import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from '../routing/privateRoute'
import ProfilePage from '../templates/profile'
import LoginPage from '../templates/login'
import DashboardLandingPage from '../templates/dashboard';
import DashboardResources from '../templates/dashboard-goal-resources'

const App = (context) => (
  <Router basepath="/app">
    <PrivateRoute {...context} path="/profile" component={ProfilePage} />
    <PrivateRoute {...context} path="/dashboard" component={DashboardLandingPage} />
    <PrivateRoute {...context} path="/dashboard/resources" component={DashboardResources} />
    <PrivateRoute path="/login" component={LoginPage} />
  </Router>
)

export default App
